import React from "react"
import { ErrorBoundary, useErrorHandler } from "react-error-boundary"

import { useWallet } from "marketplace/web3"
import {
  MetamaskPanel,
  EmptyPanel,
  ErrorPanel,
  LoadingCover,
} from "marketplace/app/components"

import useOrders from "../hooks/useOrders"
import OrderList from "./OrderList"

const Orders = () => {
  const { active, resetWalletError } = useWallet()
  const { orders, fetching, getDownloadUrl } = useOrders()

  const renderView = () => {
    if (!active) return <MetamaskPanel />
    if (fetching) return <LoadingCover />
    if (!orders.length) return <EmptyPanel type="orders" />
    if (orders.length)
      return <OrderList orders={orders} getDownloadUrl={getDownloadUrl} />
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPanel} onReset={resetWalletError}>
      {renderView()}
    </ErrorBoundary>
  )
}

export default Orders
