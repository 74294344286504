import React from "react"

import { PageView } from "marketplace/app/components"
import Orders from "marketplace/orders"

const CONTENT = {
  seo: "Orders",
  title: "Check your purchased datasets",
  description:
    // "Here you have your datasets available, both to consult the metadata and to download the files.",
    // "Here you have your purchased datasets available. You can request a download of the file as many times as you want.",
    "Here you have your purchased datasets available for download."
}

const OrdersPage = () => {
  return (
    <PageView
      seo={CONTENT.seo}
      title={CONTENT.title}
      description={CONTENT.description}
      styleName="blue"
    >
      <Orders />
    </PageView>
  )
}

export default OrdersPage
