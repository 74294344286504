import React from "react"

import { useWallet } from "marketplace/web3"

import { Flex, ColumLayout, AddressBadge } from "marketplace/app/components"
import OrderItem from "./OrderItem"

const OrderList = ({ orders, getDownloadUrl }) => {
  const { active, account } = useWallet()

  return (
    <ColumLayout
      sx={{
        px: ["32px", "32px", "45px", "45px"],
        minHeight: ["500px", "550px", "600px", "790px"],
        height: "auto",
      }}
    >
      <Flex
        sx={{
          // mx: "28px",
          mt: "24px",
          mb: "16px",
          // mt: "56px",
          justifyContent: "flex-end",
          // minHeight: "40px",
          // height: "auto",
        }}
      >
        {active && <AddressBadge address={account} />}
      </Flex>

      <Flex
        column
        className="blue-scrollbar"
        sx={{
          height: "100%",
          maxHeight: "630px",
          overflowY: "auto",
          px: ["0px", "0px", "0px", "35px"],
        }}
      >
        <Flex column sx={{ maxHeight: "inherit" }}>
          {orders.map(dataset => (
            <OrderItem
              key={dataset.id}
              dataset={dataset}
              getDownloadUrl={getDownloadUrl}
            />
          ))}
        </Flex>
      </Flex>
    </ColumLayout>
  )
}

export default OrderList
