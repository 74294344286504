import React from "react"

import {
  Flex,
  Heading,
  Icon,
  PriceLabel,
  Text,
} from "marketplace/app/components"
import ArrowUpImg from "marketplace/app/images/icons/arrow-up.svg"
import ArrowDownImg from "marketplace/app/images/icons/arrow-down.svg"
import DownloadImg from "marketplace/app/images/icons/download.svg"

import { getEtherStringFromWeiString } from "marketplace/web3/contracts/utils/unit-conversion"

function formatDatasetId(string) {
  return string.slice(0, 10) + " . . . " + string.slice(-4)
}

const STATUS_LABEL = {
  available: "Available",
  "pending-order": "Pending",
  "pending-paid": "Pending",
  "pending-queued": "Pending",
}

function formatStatusLabel(status) {
  return STATUS_LABEL[status] || ""
}

const OrderItem = ({ dataset, getDownloadUrl }) => {
  const [expanded, setExpanded] = React.useState(false)

  const toogleExpand = () => {
    setExpanded(exp => !exp)
  }

  if (dataset.status === "pending-order") return null

  return (
    <Flex sx={{ flex: 1 }}>
      <Flex
        column
        sx={{
          flex: 1,
          borderBottom: "2px solid",
          borderBottomColor: "primary",
          justifyContent: "space-between",
        }}
      >
        <Flex
          onClick={toogleExpand}
          sx={{
            flex: 1,
            cursor: "pointer",
            pt: "40px",
            pb: expanded ? "24px" : "40px",
          }}
        >
          <Flex column sx={{ flex: 1, color: expanded ? "primary" : "text" }}>
            <Heading variant="text.smallTitle">{dataset.name}</Heading>
            <Heading variant="text.smallTitle">
              {new Date(dataset.summaryDate).toLocaleDateString()}
            </Heading>
          </Flex>
          <Flex>
            <Flex
              sx={{
                px: "15px",
                py: "8px",
                color: dataset.status === "available" ? "primary" : "secondary",
                border: "1px solid",
                borderColor:
                  dataset.status === "available" ? "primary" : "secondary",
                alignSelf: "center",
                mr: "33px",
              }}
            >
              <Text variant="text.textSmall">
                {formatStatusLabel(dataset.status)}
              </Text>
            </Flex>
            <Flex>
              <Icon
                path={expanded ? ArrowUpImg : ArrowDownImg}
                title={expanded ? "Collapse" : "Expand"}
                width={24}
              />
            </Flex>
          </Flex>
        </Flex>

        {expanded && (
          <Flex
            column
            sx={{
              flex: 1,
              height: "235px",
              bg: "muted",
              px: "25px",
              pt: "45px",
              pb: "31px",
            }}
          >
            <Heading sx={{ mb: "40px" }}>{formatDatasetId(dataset.id)}</Heading>
            <Heading variant="text.homeSubtitle">
              <PriceLabel
                price={getEtherStringFromWeiString(dataset.price)}
                sxDec={{ variant: "text.textBody" }}
              />
            </Heading>
            {dataset.downloadUrl ? (
              <DownloadLink dataset={dataset} />
            ) : (
              <EnableDownload
                dataset={dataset}
                downloadHandler={getDownloadUrl}
              />
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default OrderItem

const EnableDownload = ({ dataset, downloadHandler }) => {
  const getDownloadUrlHandler = () => {
    downloadHandler(dataset.id)
  }

  return (
    <Flex
      onClick={dataset.status === "available" ? getDownloadUrlHandler : null}
    >
      <Text
        variant="text.textBody"
        sx={{
          textDecoration: "underline",
          color: dataset.status === "available" ? "text" : "disabled",
          cursor: "pointer",
        }}
      >
        Enable download
      </Text>
    </Flex>
  )
}

const DownloadLink = ({ dataset }) => {
  return (
    <a download href={dataset.downloadUrl}>
      <Text
        variant="text.textBody"
        sx={{
          textDecoration: "underline",
          color: dataset.status !== "available" && "disabled",
        }}
      >
        Download file
        <Icon
          path={DownloadImg}
          title={"Download dataset"}
          width={12}
          sx={{ ml: "10px", mt: "5px" }}
        />
      </Text>
    </a>
  )
}
